<template>
  <div class="page-repair-edit-container">
    <equipment-info />
    <error-desc @set-fields-value="setFieldsValue" ref="errorDescComponent" />
    <van-cell-group class="tel-wrapper">
      <van-field v-model="initPersonTel" label="报修电话" placeholder="请输入报修电话" />
    </van-cell-group>
    <adverse-event-form ref="adverseEventForm" />
    <div class="btn-wrapper">
      <van-row>
        <van-col :span="12">
          <van-button round class="btn-cancel" @click="handleCancel">取消</van-button>
        </van-col>
        <van-col :span="12">
          <van-button round color="#DB1E32" class="btn-repair" @click="handleSubmit">提交</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { initRepair } from '@/services/repair.js';
import { saveAttachments } from '@/services/global.js';
import { fetchAccount } from '@/services/account';
import { checkIsAllowRecord, isIOS } from '@/utils/index.js';
import wechatConfig from "@/plugins/config";
import EquipmentInfo from './components/equipment-info.vue';
import ErrorDesc from './components/error-desc.vue';
import AdverseEventForm from './components/adverse-event-form.vue';
export default {
  name: 'Repair-Edit',
  data() {
    return {
      initPersonTel: undefined,
      formValues: {
        audioList: [],
        imageList: [],
        initReason: '',
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$refs.errorDescComponent.beforeSubmit()

      // if (!(await this.checkIsBind())) {
      //   return;
      // }

      const adverseEventFormValues = this.$refs.adverseEventForm.getFormValues();
      const adverseEventReq = adverseEventFormValues.isReportAdverseEvent ? adverseEventFormValues : undefined;
      const isReportAdverseEvent = adverseEventFormValues.isReportAdverseEvent;
      if (adverseEventFormValues.isReportAdverseEvent) {
        if (!adverseEventFormValues.happenPlace) {
          Toast({
            type: 'error',
            message: '事件发生场所不能为空！',
            duration: 2000,
          });
          return;
        }
        if (!adverseEventFormValues.happenTime) {
          Toast({
            type: 'error',
            message: '事件发生日期不能为空！',
            duration: 2000,
          });
          return;
        }
        if (!adverseEventFormValues.reportTime) {
          Toast({
            type: 'error',
            message: '报告日期不能为空！',
            duration: 2000,
          });
          return;
        }
        if (!adverseEventFormValues.eventType || !adverseEventFormValues.eventType.length) {
          Toast({
            type: 'error',
            message: '不良事件类型不能为空！',
            duration: 2000,
          });
          return;
        }
        if (!adverseEventFormValues.eventLevel) {
          Toast({
            type: 'error',
            message: '不良事件等级不能为空！',
            duration: 2000,
          });
          return;
        }
        adverseEventReq.eventTypeList = adverseEventReq.eventType;
        delete adverseEventReq.eventType;
        delete adverseEventReq.isReportAdverseEvent;
      }

      if (!this.formValues.initReason.trim() && this.formValues.audioList.length === 0) {
        Toast({
          type: 'error',
          message: '故障描述不能为空！',
          duration: 2000,
        });
        return;
      }
      if (this.formValues.initReason.length > 300) {
        Toast({
          type: 'error',
          message: '故障描述字数超出限制，仅限300字以内！',
          duration: 2000,
        });
        return;
      }
      
      try {
        this.$store.dispatch('showLoading', '提交中');
        const { code, data } = await initRepair({
          departmentId: this.$store.getters.equipmentDepartmentId,
          departmentName: this.$store.getters.equipmentDepartmentName,
          equipmentId: this.$store.getters.equipmentId,
          equipmentName: this.$store.getters.equipmentName,
          initPersonId: this.$store.getters.employeeId,
          initPersonName: this.$store.getters.employeeName,
          createdBy: this.$store.getters.employeeId,
          createdByName: this.$store.getters.employeeName,
          initReason: this.formValues.initReason,
          orgId: this.$store.getters.orgId,
          orgName: this.$store.getters.orgName,
          initPersonTel: this.initPersonTel,
          createAdverseEvent: isReportAdverseEvent,
          adverseEventReq,
        });
        if (code === 0) {
          const { code } = await saveAttachments(data.id, [...this.formValues.audioList, ...this.formValues.imageList]);
          if (code === 0) {
            this.$router.push(`/repair/result?equipmentId=${this.$route.query.equipmentId}`);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch('hideLoading');
      }
    },

    setFieldsValue(values) {
      this.formValues = {
        ...this.formValues,
        ...values,
      };
    },

    handleCancel() {
      this.$router.back();
    },
    
    // 提交前，校验当前用户是否已经被解绑
    async checkIsBind() {
      if (!this.$store.getters.openId) {
        return false;
      }
      try {
        const { code } = await fetchAccount(this.$store.getters.openId);
        if (code === 0) {
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  },
  async mounted() {
    // 安卓设备需要重新进行签名操作，否则会报startRecord:invalid signature的错误，ios不需要
    // 参考：https://developers.weixin.qq.com/community/develop/doc/0008ce14a942f88d6e8bc944e51c00
    if (!isIOS()) {
      await wechatConfig.config();
    }
    checkIsAllowRecord();
    this.initPersonTel = this.$store.getters.employeePhone;
  },
  components: {
    EquipmentInfo,
    ErrorDesc,
    AdverseEventForm,
  },
}
</script>

<style lang="less">
  @import '@/styles/mixins.less';
  .page-repair-edit-container {
    width: 355px;
    min-height: 100vh;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 120px;
    .tel-wrapper {
      .van-field__control {
        .selectable();
      }
    }
    .btn-wrapper {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      background: #fff;
      box-shadow: 0 -1px 5px #eee;
      .btn-repair, .btn-cancel {
        width: 100px;
      }
    }
  }
</style>
