export default {
  bind: function (el, binding, vNode) {
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name;
      const warn = `[longpress:] provided expression '${binding.expression}' is not afunction, but has to be 
      if (compName) { warn += Found in component '${compName}'  }`;
      console.warn(warn);
    }
    // 定义变量
    let pressTimer = null;
    let startTimestamp;
    let startX;
    let startY;

    // const getSlideAngle = (dx, dy) => {
    //   return (Math.atan2(dy, dx) * 180) / Math.PI;
    // };

    const isSlideUp = (startX, startY, endX, endY) => {
      const dy = startY - endY;
      // const dx = endX - startX;

      return dy > 20;
      // if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
      //   return false;
      // }

      // const angle = getSlideAngle(dx, dy);
      // if (angle >= 45 && angle < 135) {
      //   return true;
      // }
    };

    // 定义函数处理程序
    // 创建计时器（ 1秒后执行函数 ）
    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return;
      }
      startX = e.touches[0].pageX;
      startY = e.touches[0].pageY;
      startTimestamp = Date.now();
      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          if (vNode.context.onLongPressStart) {
            vNode.context.onLongPressStart({
              startPosition: {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
              },
            });
          }
          // 执行函数
          // handler();
        }, 300);
      }
    };
    // 取消计时器
    const cancel = (e) => {
      if (e.type === 'click') {
        return;
      }
      const isOverDelay = Date.now() - startTimestamp > 300;
      // 检查计时器是否有值
      if (pressTimer !== null) {
        if (vNode.context.onLongPressEnd) {
          vNode.context.onLongPressEnd(
            isOverDelay,
            isSlideUp(
              startX,
              startY,
              e.changedTouches[0].pageX,
              e.changedTouches[0].pageY,
            ),
          );
        }
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };
    // 运行函数
    // const handler = (e) => {
    //   // 执行传递给指令的方法
    //   binding.value(e);
    // };
    // 添加事件监听器
    // el.addEventListener('mousedown', (e) => {
    //   console.log('mousedown');
    //   start(e);
    // });
    el.addEventListener('touchstart', (e) => {
      console.log('touchstart');
      start(e);
    });
    // // 取消计时器
    // el.addEventListener('click', (e) => {
    //   console.log('click');
    //   cancel(e);
    // });
    // el.addEventListener('mouseout', (e) => {
    //   console.log('mouseout');
    //   cancel(e);
    // });
    el.addEventListener('touchend', (e) => {
      console.log('touchend');
      cancel(e);
    });
    el.addEventListener('touchcancel', (e) => {
      console.log('touchcancel');
      cancel(e);
    });
  },
  unbind: function () {},
};
