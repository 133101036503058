import request from '@/utils/request';

/**
 * 保存附件
 * @param bizId （业务id，可能为机构id，也可能为人员id）
 * @param attachments
 * @returns
 */
export async function saveAttachments(bizId, attachments) {
  return request(`/v3/common/attachmentInfo/save/${bizId}`, {
    method: 'POST',
    data: attachments,
  });
}
