<template>
  <van-form class="adverse-event-form-wrapper">
    <van-cell-group>
      <van-cell center title="是否上报不良事件">
        <template #right-icon>
          <van-switch v-model="isReportAdverseEvent" size="24" active-color="#db1e32" />
        </template>
      </van-cell>
      <div v-show="isReportAdverseEvent">
        <van-cell center required title="事件发生场所" is-link @click="handleSelect('happenPlace')">
          <template #default>
            <a>{{ selectedHappenPlace }}</a>
          </template>
        </van-cell>
        <van-cell center required title="事件发生日期" is-link @click="handleSelect('happenTime')">
          <template #default>
            <a>{{ selectedHappenTime }}</a>
          </template>
        </van-cell>
        <van-cell center required title="报告日期" is-link @click="handleSelect('reportTime')">
          <template #default>
            <a>{{ selectedReportTime }}</a>
          </template>
        </van-cell>
        <van-cell center required title="不良事件类别" is-link @click="handleSelect('eventType')">
          <template #default>
            <a>{{ eventTypeText }}</a>
          </template>
        </van-cell>
        <van-cell center required title="不良事件等级" is-link @click="handleSelect('eventLevel')">
          <template #default>
            <a>{{ selectedEventLevel }}</a>
          </template>
        </van-cell>
        <van-cell center title="当事人类别" is-link @click="handleSelect('personType')">
          <template #default>
            <a>{{ selectedPersonType }}</a>
          </template>
        </van-cell>
        <van-cell center title="当事人职称" is-link @click="handleSelect('personTitle')">
          <template #default>
            <a>{{ selectedPersonTitle }}</a>
          </template>
        </van-cell>
        <van-field
            v-model="personWorkYears"
            name="personWorkYears"
            type="digit"
            label="当事人工作年限"
            placeholder="请输入"
          />
        <van-field
          v-model="siteSituation"
          name="siteSituation"
          label="在场人员或相关科室"
          placeholder="请输入"
        />
        <van-field
          v-model="adverseResult"
          rows="2"
          autosize
          label="不良后果"
          type="textarea"
          maxlength="200"
          placeholder="请输入"
          show-word-limit
        />
      </div>
    </van-cell-group>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showEventTypePicker" position="bottom" round @click-overlay="handleClickOverlay">
      <div class="event-type-selector-wrapper">
        <selector
          ref="selector"
          :initial-options="eventTypePickerOptions" 
          :initial-selected-ids="selectedEventType"
          @confirm="handleSelectorConfirm"
          @cancel="handleSelectorCancel"
        />
      </div>
    </van-popup>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
        @cancel="showDatePicker = false"
      />
    </van-popup>
  </van-form>
</template>

<script>
import { fetchAdverseEventParams } from '@/services/adverseEvent';
import { formatDate } from '@/utils';
import Selector from '@/components/selector';
export default {
  name: 'adverse-event-form',
  data() {
    return {
      isReportAdverseEvent: false,
      showPicker: false,
      showEventTypePicker: false,
      showDatePicker: false,
      columns: [],
      eventLevelOptions: [],
      eventTypeOptions: [],
      happenPlaceOptions: [],
      personTitleOptions: [],
      personTypeOptions: [],
      currentField: undefined,
      selectedHappenTime: undefined,
      selectedReportTime: undefined,
      selectedEventLevel: undefined,
      selectedEventType: [],
      selectedHappenPlace: undefined,
      selectedPersonTitle: undefined,
      selectedPersonType: undefined,
      siteSituation: undefined,
      personWorkYears: undefined,
      adverseResult: undefined,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2100, 0, 1),
      currentDate: new Date(),
    }
  },
  computed: {
    eventTypePickerOptions() {
      return this.eventTypeOptions.map(i => ({ label: i, value: i }));
    },
    eventTypeText() {
      return this.selectedEventType.join('，');
    }
  },
  async created() {
    try {
      const { code, data } = await fetchAdverseEventParams();
      if (code === 0) {
        this.eventLevelOptions = data.eventLevel;
        this.eventTypeOptions = data.eventType;
        this.happenPlaceOptions = data.happenPlace;
        this.personTitleOptions = data.personTitle;
        this.personTypeOptions = data.personType;
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    handleSelectorConfirm(selectedIds) {
      this.selectedEventType = selectedIds;
      this.showEventTypePicker = false;
    },
    handleSelectorCancel() {
      this.showEventTypePicker = false;
    },
    handleClickOverlay() {
      this.$refs.selector.handleCancel();
    },
    onConfirm(val) {
      switch (this.currentField) {
        case 'happenPlace': 
          this.selectedHappenPlace = val;
          break;
        case 'eventLevel':
          this.selectedEventLevel = val;
          break;
        case 'personTitle':
          this.selectedPersonTitle = val;
          break;
        case 'personType':
          this.selectedPersonType = val;
          break;
        default: break;
      }
      this.showPicker = false;
    },
    onDateConfirm(date) {
      this.currentDate = date;
      switch (this.currentField) {
        case 'happenTime':
          this.selectedHappenTime = formatDate(date);
          break;
        case 'reportTime':
          this.selectedReportTime = formatDate(date);
          break;
        default: break;
      }
      this.showDatePicker = false;
    },
    handleSelect(fieldName) {
      this.currentField = fieldName;
      switch (fieldName) {
        case 'happenPlace':
          this.columns = this.happenPlaceOptions;
          this.showPicker = true;
          break;
        case 'eventLevel':
          this.columns = this.eventLevelOptions;
          this.showPicker = true;
          break;
        case 'personTitle':
          this.columns = this.personTitleOptions;
          this.showPicker = true;
          break;
        case 'personType':
          this.columns = this.personTypeOptions;
          this.showPicker = true;
          break;
        case 'eventType':
          this.showEventTypePicker = true;
          return;
        case 'happenTime':
        case 'reportTime':
          this.showDatePicker = true;
          break;
        default: break;
      }      
    },
    getFormValues() {
      return {
        happenTime: this.selectedHappenTime,
        reportTime: this.selectedReportTime,
        eventLevel: this.selectedEventLevel,
        eventType: this.selectedEventType,
        happenPlace: this.selectedHappenPlace,
        personTitle: this.selectedPersonTitle,
        personType: this.selectedPersonType,
        siteSituation: this.siteSituation,
        personWorkYears: this.personWorkYears,
        adverseResult: this.adverseResult,
        isReportAdverseEvent: this.isReportAdverseEvent,
      }
    }
  },
  components: {
    Selector,
  }
}
</script>

<style lang="less">
  @import '@/styles/mixins.less';
  .event-type-selector-wrapper {
    width: 100%;
    height: 300px;
    overflow: scroll;
  }
  .adverse-event-form-wrapper {
    .van-field__control {
      .selectable();
    }
  }

</style>