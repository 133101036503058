<template>
  <van-swipe-cell>
    <div class="audio-wrapper">
      <iconfont name="iconbofang" class="icon-play" @click.native="handlePlay" v-if="audioData.status === 'stop'" />
      <iconfont v-else name="iconstop" class="icon-play" @click.native="handleStop" />
      <div class="wave">
        <wave />
      </div>
      <div class="duration">{{ duration }}</div>
    </div>
    <template #right>
      <iconfont name="iconshanchu" class="icon-delete" @click.native="handleDelete" />
    </template>
  </van-swipe-cell>
</template>

<script>
import wave from './wave.vue';
export default {
  name: 'audiobar',
  props: {
    audioData: Object,
    currentPlayingAudioId: String
  },
  computed: {
    duration() {
      if (!this.audioData || !this.audioData.duration) {
        return '00:00:00';
      }
      const duration = this.audioData.duration;
      if (duration >= 60) {
        const h = Math.floor(duration / 3600);
        const m = Math.floor((duration / 60 % 60));
        const s = Math.floor((duration % 60));
        return `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`;
      }
      return `00:00:${duration > 9 ? duration : '0' + duration}`;
    }
  },
  methods: {
    handlePlay() {
      this.$emit('on-play', this.audioData.localId)
      if (this.currentPlayingAudioId) {
        window.wx.stopVoice({
          localId: this.currentPlayingAudioId,
          success: () => {
            window.wx.playVoice({
              localId: this.audioData.localId,
            });
          }
        });
      } else {
        window.wx.playVoice({
          localId: this.audioData.localId,
        });
      }
    },
    handleStop() {
      this.$emit('on-stop', this.audioData.localId);
      window.wx.stopVoice({
        localId: this.audioData.localId,
      });
    },
    handleDelete() {
      if (this.currentPlayingAudioId) {
         window.wx.stopVoice({
           localId: this.currentPlayingAudioId,
         });
      }
      this.$emit('on-delete', this.audioData.localId);
    }
  },
  components: {
    wave,
  }
}
</script>

<style lang="less">
  .audio-wrapper {
    position: relative;
    width: 90%;
    height: 40px;
    margin: 10px auto;
    background: #eee;
    border-radius: 20px;
    overflow: hidden;
    .icon-play {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
    }
    .wave {
      position: absolute;
      width: 50%;
      height: 30px;
      left: 48px;
      top: 50%;
      transform: translateY(-50%);
    }
    .duration {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 15px;
    }
  }
  .van-swipe-cell__right {
    text-align: center;
  }
  .icon-delete {
    width: 22px;
    height: 22px;
    margin-top: 9px;
  }
</style>
