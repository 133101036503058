<template>
  <div class="wave-wrapper">
    <iconfont name="iconshengboyuyinxiaoxi" class="icon-wave" />
    <iconfont name="iconshengboyuyinxiaoxi" class="icon-wave" />
    <iconfont name="iconshengboyuyinxiaoxi" class="icon-wave" />
  </div>
</template>

<script>
  export default {
    name: 'wave',
  }
</script>

<style lang="less">
  .wave-wrapper {
    height: 100%;
    .icon-wave {
      display: inline-block;
      width: 28px;
      height: 28px;
    }
  }
</style>
