<template>
  <div class="selector-wrapper">
    <van-row class="header">
      <van-col span="12" class="left"><span @click="handleCancel">取消</span></van-col>
      <van-col span="12" class="right"><span @click="handleConfirm">确定</span></van-col>
    </van-row>
    <div 
      v-for="option in options" 
      :key="option.value" 
      :class="{ active: option.active }" 
      class="option"
      @click="handleSelectOption(option)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'selector',
    props: {
      initialOptions: Array,
      initialSelectedIds: Array,
    },
    data() {
      return {
        options: [],
        selectedIds: [],
      }
    },
    watch: {
      initialSelectedIds(newIds) {
        this.selectedIds = [...newIds];
      }
    },
    methods: {
      handleSelectOption(option) {
        this.options = this.options.map(i => {
          if (i.value === option.value) {
            if (option.active) {
              this.selectedIds = this.selectedIds.filter(i => i !== option.value);
            } else {
              this.selectedIds.push(option.value);
            }
            return {
              ...i,
              active: !option.active,
            }
          }
          return i;
        });
      },
      handleCancel() {
        this.options = this.options.map(i => {
          const isActive = this.initialSelectedIds.includes(i.value);
          return {
            ...i,
            active: isActive,
          }
        });
        this.$emit('cancel');
      },
      handleConfirm() {
        console.log(this.selectedIds);
        this.$emit('confirm', [...this.selectedIds]);
      }
    },
    created() {
      this.options = this.initialOptions.map(i => {
        return {
          ...i,
          active: false,
        }
      });
    }
  }
</script>

<style lang="less" scoped>
  .selector-wrapper {
    width: 375px;
    padding-top: 50px;
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
  .left {
    color: #969799;
  }
  .right {
      text-align: right;
      color: #576b95;
    }
  }
.option {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #eee;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;

    &.active {
      color: red;
    }
  }
</style>