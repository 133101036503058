import request from '@/utils/request';

/**
 * 发起报修
 * @param {*} data
 * @returns
 */
export async function initRepair(data = {}) {
  return request({
    url: '/v3/mp/repair/init',
    method: 'POST',
    data,
  });
}
