<template>
  <div class="equipment-info-wrapper">
    <van-cell title="设备名称" :value="equipmentName" />
    <van-cell title="设备制造商" :value="equipmentManufacturerName" />
    <van-cell title="设备型号" :value="equipmentModelName" />
    <van-cell title="设备序列号" :value="equipmentSn" />
    <van-cell title="设备编号" :value="equipmentNo" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Equipment-Info',
  computed: {
    ...mapGetters([
      'equipmentId',
      'equipmentName',
      'equipmentManufacturerName',
      'equipmentModelName',
      'equipmentSn',
      'equipmentNo',
    ]),
  }
}
</script>

<style lang="less" scoped>
  .equipment-info-wrapper {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
  }
</style>
