<template>
  <div class="wrapper">
    <van-row class="row-title">
      <van-col :span="20">
        <span class="col-title">故障描述</span>
        <span class="col-desc">点击右侧图标切换报修方式</span>
      </van-col>
      <van-col :span="4" class="col-icon">
        <div class="icon-wrapper" @click="handleChangeMode">
          <iconfont :name="modeIconName" />
        </div>
      </van-col>
    </van-row>
    <div class="desc-wrapper">
      <van-field
        v-show="mode === 'desc'"
        v-model="description"
        rows="2"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请填写设备的故障描述"
        class="desc-input"
        show-word-limit
        @input="handleDescInput"
      />
      <div v-show="mode === 'voice'" class="voice-wrapper">
        <audiobar
          v-for="item in audioList"
          :key="item.localId"
          :audio-data="item"
          :current-playing-audio-id="currentPlayingAudioId"
          @on-delete="handleDeleteAudio"
          @on-play="handlePlayAudio"
          @on-stop="handleStopAudio"
        />
        <div
          round
          type="primary"
          v-longpress
          class="btn-hold"
          @long-press-start="onLongPressStart"
          @long-press-end="onLongPressEnd"
        >按住说话</div>
      </div>
    </div>
    <div class="uploader-wrapper">
      <yxk-uploader @on-change="handleUploaderChange" :image-list="imageList"/>
    </div>
    <div v-show="isRecording" class="recoding-bar">
      <div class="recoding-bar-inner">
        <div class="icon-record"></div>
        <div class="wave"><wave /></div>
        <div class="duration">{{ formattedRecordDuration }}</div>
      </div>
      <div class="slide-up-wrapper">
        <iconfont name="iconshanghua" class="icon-slide-up" />
        <h4>上滑取消</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import Uploader from '@/components/uploader/index.vue';
import AudioBar from '@/components/audiobar/index.vue';
import Wave from '@/components/audiobar/wave.vue';
import LongPress from '@/directives/long-press.js';
import { uploadAudio2Wechat } from '@/services/wx';
import { uploadMedia, fetchAvinfo } from '@/services/qiniu';
import {
  AttachmentCategory,
} from '@/utils/constant';

export default {
  name: 'Error-Desc',
  directives: {
    longpress: LongPress,
  },
  data() {
    return {
      description: '',
      audioList: [],
      imageList: [],
      isRecording: false,
      recordDuration: 0,
      currentPlayingAudioId: undefined,
      mode: 'desc',
      recordTimingInterval: null,
      playCountDownInterval: null,
      isOnLongPressEnd: false,
    }
  },
  computed: {
    modeIconName() {
      return this.mode === 'desc' ? 'iconjianpanqiehuan' : 'iconyuyinqiehuan'
    },
    formattedRecordDuration() {
      if (this.recordDuration >= 60) {
        const h = Math.floor(this.recordDuration / 3600);
        const m = Math.floor((this.recordDuration / 60 % 60));
        const s = Math.floor((this.recordDuration % 60));
        return `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}:${s > 9 ? s : '0' + s}`;
      }
      return `00:00:${this.recordDuration > 9 ? this.recordDuration : '0' + this.recordDuration}`
    }
  },
  watch: {
    isRecording(recordingStatus) {
      if (!recordingStatus) {
        this.stopRecordTiming();
      } else {
        this.recordDuration = 0;
      }
    },
    audioList(newList) {
      this.$emit('set-fields-value', {
        audioList: newList.map(item => ({
          category: item.category,
          contentLength: item.contentLength,
          contentType: item.contentType,
          res: item.res,
        }))
      });
    },
    imageList(newList) {
      this.$emit('set-fields-value', {
        imageList: newList.map(item => ({
          category: item.category,
          contentLength: item.contentLength,
          contentType: item.contentType,
          res: item.res,
        }))
      })
    }
  },
  methods: {
    handleChangeMode() {
      this.mode = this.mode === 'desc' ? 'voice' : 'desc';
    },
    handleDescInput(value) {
      this.$emit('set-fields-value', {
        initReason: value,
      });
    },
    stopRecordTiming() {
      this.isOnLongPressEnd = false;
      if (this.recordTimingInterval) {
        clearInterval(this.recordTimingInterval);
      }
    },
    startRecordTiming() {
      this.stopRecordTiming();
      this.recordTimingInterval = setInterval(() => {
        if (this.recordDuration >= 59) {
          console.log('59s自动停止录音');
          this.stopRecordTiming();
          this.onLongPressEnd(true, false);
        } else {
          this.recordDuration += 1;
        }
      }, 1000);
    },
    stopPlayCountDown() {
      if (this.playCountDownInterval) {
        clearInterval(this.playCountDownInterval);
      }
    },
    startPlayCountDown(localId) {
      this.stopPlayCountDown();
      this.playCountDownInterval = setInterval(() => {
        this.audioList = this.audioList.map(item => {
          if (item.localId === localId) {
            const currentDuration = item.duration;
            if (!currentDuration) {
              this.stopPlayCountDown();
            }
            return {
              ...item,
              duration: currentDuration > 0 ? currentDuration - 1 : 0
            }
          }
          return item;
        })
      }, 1000);
    },

    onLongPressStart() {
      if (this.audioList.length === 3) {
        Toast('最多只能录制3条，如需更换录音请删除某条！');
        return;
      }
      window.wx.startRecord();
      this.startRecordTiming();
      this.isRecording = true;
      this.isOnLongPressEnd = false;
    },

    onLongPressEnd(isLongPress, isSlideUp) {
      console.log('this.isOnLongPressEnd: ', this.isOnLongPressEnd);
      if (this.isOnLongPressEnd) {
        return;
      }
      this.isOnLongPressEnd = true;
      console.log('isSlideUp: ', isSlideUp);
      if (this.audioList.length === 3) {
        Toast('最多只能录制3条，如需更换录音请删除某条！');
        window.wx.stopRecord();
        this.isRecording = false;
        this.isOnLongPressEnd = false;
        return;
      }
      if (isSlideUp) {
        window.wx.stopRecord();
        this.isRecording = false;
        this.isOnLongPressEnd = false;
        return;
      }
      if (isLongPress) {
        if (this.isRecording) {
          window.wx.stopRecord({
            success: async (res) => {
              this.stopRecordHandler(res.localId);
            },
            fail: (error) => {
              this.isRecording = false;
              this.isOnLongPressEnd = false;
              console.error(error);
            }
          });
        }
      } else {
        Toast('请长按按钮进行操作！');
        this.isRecording = false;
        this.isOnLongPressEnd = false;
        window.wx.stopRecord();
      }
    },

    async getDuration(res) {
      try {
        const audioMetaRes = await fetchAvinfo(res);

        if (audioMetaRes.code === 0) {
          return Math.round(audioMetaRes.data.format.duration);
        }
        return 0;
      } catch (error) {
        console.error(error);
        return 0;
      }
    },

    async stopRecordHandler(localId) {
      console.log('一分钟调用');
      this.isRecording = false;
      this.isOnLongPressEnd = false;
      const serverId = await uploadAudio2Wechat(localId);
      const { code, data } = await uploadMedia(2, serverId);
      const duration = await this.getDuration(data.res);
      if (code === 0) {
        this.audioList.push({
          localId: localId,
          res: data.res,
          contentLength: data.fileSize,
          contentType: data.fileMimeType,
          category: AttachmentCategory.MP_REPAIR_FAILURE_VOICE,
          status: 'stop',
          duration: duration,
          originalDuration: duration,
        });
      }
    },

    handleUploaderChange(uploaderRes) {
      switch (uploaderRes.type) {
        case 'add':
          this.imageList.push(uploaderRes.payload);
          break;
        case 'delete':
          this.imageList = this.imageList.filter(item => item.localId !== uploaderRes.payload.localId);
          break;
      }
    },

    handleDeleteAudio(localId) {
      this.audioList = this.audioList.filter(item => item.localId !== localId);
    },

    handlePlayAudio(localId) {
      this.currentPlayingAudioId = localId;
      this.audioList = this.audioList.map(item => {
        if (item.localId === localId) {
          return {
            ...item,
            status: 'play',
          }
        }
        return {
          ...item,
          status: 'stop',
        }
      });
      this.startPlayCountDown(localId);
    },

    handleStopAudio(localId) {
      this.currentPlayingAudioId = undefined;
      this.audioList = this.audioList.map(item => {
        if (item.localId === localId) {
          return {
            ...item,
            status: 'stop',
            duration: item.originalDuration,
          }
        }
        return item;
      });
      this.stopPlayCountDown();
    },

    beforeSubmit() {
      if (this.currentPlayingAudioId) {
        window.wx.stopVoice({
          localId: this.currentPlayingAudioId,
        });
        this.currentPlayingAudioId = undefined;
      }
      this.audioList = this.audioList.map(item => ({
        ...item,
        status: 'stop',
        duration: item.originalDuration,
      }));
      this.stopPlayCountDown();
    }
  },
  mounted() {
    // 监听语音播放完毕接口
    window.wx.onVoicePlayEnd({
      success: (res) => {
        console.log('播放完毕的是', res.localId);
        this.currentPlayingAudioId = undefined;
        this.audioList = this.audioList.map(item => ({
          ...item,
          status: 'stop',
          duration: item.originalDuration,
        }));
        this.stopPlayCountDown();
      }
    });

    window.wx.onVoiceRecordEnd({
    // 录音时间超过一分钟没有停止的时候会执行 complete 回调
      complete: (res) => {
        console.log('超过一分钟的录音是：', res.localId);
        if (!this.isOnLongPressEnd) {
          console.log('onVoiceRecordEnd');
          this.stopRecordHandler(res.localId);
        } else {
          this.stopRecordTiming();
          this.isRecording = false;
          this.isOnLongPressEnd = false;
        }
      }
    });
  },
  components: {
    yxkUploader: Uploader,
    audiobar: AudioBar,
    Wave: Wave,
  }
}
</script>

<style lang="less">
  @import '@/styles/mixins.less';

  .wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background: #fff;

    .row-title {
      height: 28px;
      line-height: 28px;
      overflow: hidden;
      .col-title {
        font-size: 18px;
        font-weight: 600;
        color: #333;
      }

      .col-desc {
        color: #C3C3C3;
        font-size: 10px;
        margin-left: 10px;
      }

      .col-icon {
        text-align: right;
        align-self: center;
      }
      .icon-wrapper {
        display: inline-block;
        border: 1px solid #626161;
        height: 28px;
        width: 28px;
        border-radius: 100%;
        overflow: hidden;
      }
    }
    .uploader-wrapper {
      margin-top: 10px;
    }
    .voice-wrapper {
      margin-top: 10px;
      .btn-hold {
        margin: 20px auto;
        width: 140px;
        height: 64px;
        border-radius: 32px;
        background: #DB1E32;
        text-align: center;
        line-height: 64px;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        
      }
    }
    .desc-wrapper {
      width: 100%;
      overflow: hidden;
      .desc-wrapper {
        width: 100%;
      }

      .van-field__control {
        .selectable();
      }
    }

    .recoding-bar {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .6);
      z-index: 1000;
      
      .recoding-bar-inner {
        position: absolute;
        left: 50%;
        top: 15%;
        transform: translateX(-50%);
        width: 80%;
        height: 52px;
        border-radius: 26px;
        background: #fff;
        
        .icon-record {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background: #DB1E32;
        }

        .wave {
          position: absolute;
          left: 60px;
          top: 50%;
          transform: translateY(-50%);
          // -webkit-animation: .8s linear infinite alternate scaleWave;
          // animation: .8s linear infinite alternate scaleWave;
          // transform-origin: 0 0;
          // -webkit-transform-origin: 0 0;
        }

        .duration {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 500;
          font-size: 16px;
        }
      }

      .slide-up-wrapper {
        position: absolute;
        left: 50%;
        top: calc(15% + 80px);
        transform: translateX(-50%);
        -webkit-animation: 1.5s linear infinite alternate slideUp;
        animation: 1.5s linear infinite alternate slideUp;
        .icon-slide-up {
          width: 70px;
          height: 70px;
        }
        h4 {
          margin: 0;
          text-align: center;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
</style>
